import { render, staticRenderFns } from "./OntologyPlot.vue?vue&type=template&id=04626bac&scoped=true&"
import script from "./OntologyPlot.vue?vue&type=script&lang=ts&"
export * from "./OntologyPlot.vue?vue&type=script&lang=ts&"
import style0 from "./OntologyPlot.vue?vue&type=style&index=0&id=04626bac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04626bac",
  null
  
)

export default component.exports