var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_vm._v("Documentation")]),_c('p',{staticClass:"blockquote"},[_c('vue-markdown',{attrs:{"source":_vm.docs,"breaks":false}})],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_vm._v("Evidence summary")]),(_vm.stat)?_c('p',[_c('span',[_vm._v("Number of evidence items:  ")]),_c('b',[_vm._v(_vm._s(_vm.stat.numEvidenceItems))]),_c('br'),_c('span',[_vm._v("Aggregated association strength score:  ")]),_vm._v(" "+_vm._s(_vm.stat.aggAssocStrengthScore)+" "),_c('span',[_vm._v("       ")]),_c('span',[_vm._v("Average score:  ")]),_c('b',[_vm._v(_vm._s(_vm.stat.avgAssocStrengthScore))]),_c('br'),_c('span',[_vm._v("Aggregated evidence score:  ")]),_vm._v(" "+_vm._s(_vm.stat.aggEvidenceScore)+" "),_c('span',[_vm._v("       ")]),_c('span',[_vm._v("Average score:  ")]),_c('b',[_vm._v(_vm._s(_vm.stat.avgEvidenceScore))])]):_vm._e(),(_vm.histogramSeries)?_c('div',[_c('assoc-summary-chart',{attrs:{"assoc-scores":_vm.histogramSeries.assocScores,"evidence-scores":_vm.histogramSeries.evidenceScores}})],1):_vm._e()])],1),_c('h4',[_vm._v("Association evidence")]),_c('v-data-table',{attrs:{"headers":_vm.assocHeaders,"items":_vm.assocItems,"show-expand":"","item-key":"idx"},scopedSlots:_vm._u([{key:"header.mapping_score",fn:function(ref){
var header = ref.header;
return [_c('tooltip',{attrs:{"docs":_vm.$store.state.docs.scores.mappingScore}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.assoc_score",fn:function(ref){
var header = ref.header;
return [_c('tooltip',{attrs:{"docs":_vm.$store.state.docs.scores.assocScore}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.evidence_score",fn:function(ref){
var header = ref.header;
return [_c('tooltip',{attrs:{"docs":_vm.$store.state.docs.scores.evidenceScore}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"item.subject_term",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"font-weight-thin"},[_vm._v("Gwas:")]),_vm._v("   "),_c('span',[_c('code',[_vm._v(_vm._s(item.subject_id))])]),_c('br'),_c('a',{attrs:{"href":item.subject_url,"target":"_blank"}},[_c('span',[_vm._v(_vm._s(item.subject_term))])])])]}},{key:"item.object_term",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"font-weight-thin"},[_vm._v("Gwas:")]),_vm._v("   "),_c('span',[_c('code',[_vm._v(_vm._s(item.object_id))])]),_c('br'),_c('a',{attrs:{"href":item.object_url,"target":"_blank"}},[_c('span',[_vm._v(_vm._s(item.object_term))])])])]}},{key:"item.links",fn:function(ref){
var item = ref.item;
return [(item.topicUrl)?_c('div',[_c('div',[_c('span',[_c('v-icon',[_vm._v("mdi-open-in-new")]),_c('a',{attrs:{"href":item.topicUrl,"target":"_blank"}},[_vm._v("EpiGraphDB topic view")])],1)])]):_c('div',[_vm._v("N/A")])]}},{key:"item.effect_size",fn:function(ref){
var item = ref.item;
return [(Math.abs(item.effect_size) <= 0.001)?_c('div',[_vm._v(" "+_vm._s(item.effect_size.toExponential(4))+" ")]):_c('div',[_vm._v(" "+_vm._s(item.effect_size.toFixed(4))+" ")])]}},{key:"item.se",fn:function(ref){
var item = ref.item;
return [(Math.abs(item.se) <= 0.001)?_c('div',[_vm._v(" "+_vm._s(item.se.toExponential(4))+" ")]):_c('div',[_vm._v(" "+_vm._s(item.se.toFixed(4))+" ")])]}},{key:"item.pval",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.pval.toExponential(4))+" ")])]}},{key:"item.mapping_score",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.mapping_score.toFixed(4))+" ")])]}},{key:"item.assoc_score",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.assoc_score.toFixed(4))+" ")])]}},{key:"item.evidence_score",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.evidence_score.toFixed(4))+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',[_c('h5',[_vm._v("Entity harmonization data")]),_c('json-viewer',{attrs:{"theme":"json-viewer-gruvbox-dark","expand-depth":4,"value":item.mapping_data}}),_c('h5',[_vm._v("EpiGraphDB relationship data")]),_c('json-viewer',{attrs:{"theme":"json-viewer-gruvbox-dark","expand-depth":9,"value":item.rel_data}})],1)])]}}])}),(_vm.assocItems.length > 0)?_c('div',[_c('h4',[_vm._v("Plots")]),_c('v-dialog',{attrs:{"width":"1080"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","tile":"","color":"secondary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Focus ")])]}}],null,false,3976406451),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}},[_c('forest-plot',{attrs:{"assoc-data":_vm.assocItems}})],1),_c('div',[_c('forest-plot',{attrs:{"assoc-data":_vm.assocItems}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }