



































import Vue from "vue";

export default Vue.extend({
  name: "LoadingScreen",
  components: {
    //
  },
  props: {
    message: {
      type: String,
      default: null,
    },
    stage: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    carousel: 0,
    docList: [],
    numDocs: 10,
  }),
  computed: {
    testLoading(): boolean {
      return this.$route.name == "Loading";
    },
    snackbarVisible(): boolean {
      return this.$store.state.snackbar.visible;
    },
    carouselCycle(): boolean {
      const regular = true;
      if (this.snackbarVisible) return false;
      if (this.testLoading) return false;
      return regular;
    },
  },
  mounted: async function () {
    this.docList = await this.getDocs();
  },
  methods: {
    async getDocs(): Promise<string[]> {
      const fullDocs = await this.$store.getters["docs/getFlattenDocs"];
      if (this.testLoading) {
        return fullDocs;
      }
      return this._.sampleSize(fullDocs, this.numDocs);
    },
  },
});
