





































































import Vue from "vue";
export default Vue.extend({
  name: "AppBar",
  data: () => ({
    appTitle: "Annotated Semantic Queries",
  }),
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    navbarId() {
      if (this.currentRouteName == "TripleView") {
        return "navbar-triple";
      } else {
        return "navbar";
      }
    },
  },
});
