







































import Vue from "vue";
import { PropType } from "vue";

export default Vue.extend({
  name: "OntologyDiagramMappingTable",
  components: {
    //
  },
  props: {
    data: {
      type: Array as PropType<Array<any>>,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Ontology entity",
          value: "source_ent_term",
        },
        {
          text: "Query term",
          value: "target_ent_term",
        },
        {
          text: "Semantic similarity",
          value: "similarity_score",
        },
      ],
    };
  },
  computed: {
    items(): Array<any> {
      return this._.chain(this.data)
        .map((item) => ({
          ...item,
          ent_url: `https://epigraphdb.org/entity?meta_node=Efo&id=${item.source_ent_id}`,
        }))
        .value();
    },
  },
  methods: {
    sanitiseSimScore(score: number): number {
      return Math.abs(score);
    },
  },
});
