






















import Vue from "vue";
import { PropType } from "vue";

export default Vue.extend({
  name: "DocsDialog",
  components: {
    //
  },
  props: {
    docs: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
});
