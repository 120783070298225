











import Vue from "vue";

export default Vue.extend({
  name: "SummaryStats",
  components: {
    //
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      //
    };
  },
  computed: {
    tripleView(): boolean {
      return this.$route.name == "TripleView";
    },
  },
  methods: {
    //
  },
});
