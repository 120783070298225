var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_vm._v("Documentation")]),_c('p',{staticClass:"blockquote"},[_c('vue-markdown',{attrs:{"source":_vm.docs,"breaks":false}})],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_vm._v("Evidence summary")]),(_vm.stat)?_c('p',[_c('span',[_vm._v("Number of evidence items:  ")]),_c('b',[_vm._v(_vm._s(_vm.stat.numEvidenceItems))]),_c('br'),_c('span',[_vm._v("Aggregated triple strength score:  ")]),_vm._v(" "+_vm._s(_vm.stat.aggTripleStrengthScore)+" "),_c('span',[_vm._v("       ")]),_c('span',[_vm._v("Average score:  ")]),_c('b',[_vm._v(_vm._s(_vm.stat.avgTripleStrengthScore))]),_c('br'),_c('span',[_vm._v("Aggregated evidence score:  ")]),_vm._v(" "+_vm._s(_vm.stat.aggEvidenceScore)+" "),_c('span',[_vm._v("       ")]),_c('span',[_vm._v("Average score:  ")]),_c('b',[_vm._v(_vm._s(_vm.stat.avgEvidenceScore))])]):_vm._e(),(_vm.histogramSeries)?_c('div',[_c('triple-summary-chart',{attrs:{"triple-scores":_vm.histogramSeries.tripleScores,"evidence-scores":_vm.histogramSeries.evidenceScores}})],1):_vm._e()])],1),_c('h4',[_vm._v("Triple evidence")]),_c('v-data-table',{attrs:{"headers":_vm.tripleHeaders,"items":_vm.tripleItems,"show-expand":"","item-key":"idx"},scopedSlots:_vm._u([{key:"header.mapping_score",fn:function(ref){
var header = ref.header;
return [_c('tooltip',{attrs:{"docs":_vm.$store.state.docs.scores.mappingScore}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.triple_score",fn:function(ref){
var header = ref.header;
return [_c('tooltip',{attrs:{"docs":_vm.$store.state.docs.scores.tripleScore}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.evidence_score",fn:function(ref){
var header = ref.header;
return [_c('tooltip',{attrs:{"docs":_vm.$store.state.docs.scores.evidenceScore}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"item.triple_lower",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item["triple_label"]))])])])]}},{key:"item.triple_subject",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"font-weight-thin"},[_vm._v("LiteratureTerm:")]),_vm._v("   "),_c('span',[_c('code',[_vm._v(_vm._s(item["triple_subject_id"]))])]),_c('br'),_c('span',[_vm._v(_vm._s(item.triple_subject))])])]}},{key:"item.triple_object",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"font-weight-thin"},[_vm._v("LiteratureTerm:")]),_vm._v("   "),_c('span',[_c('code',[_vm._v(_vm._s(item["triple_object_id"]))])]),_c('br'),_c('span',[_vm._v(_vm._s(item.triple_object))])])]}},{key:"item.literature_count",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.literature_count)+" "),_c('br'),_c('literature-dialog',{attrs:{"item":item,"triple":_vm.triplesForLiterature[item.idx]}})],1)]}},{key:"item.mapping_score",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.mapping_score.toFixed(4))+" ")])]}},{key:"item.triple_score",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.triple_score.toFixed(4))+" ")])]}},{key:"item.evidence_score",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.evidence_score.toFixed(4))+" ")])]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_c('v-icon',[_vm._v("mdi-open-in-new")]),_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v("EpiGraphDB entity view")])],1),_c('br'),_c('span',[_c('v-icon',[_vm._v("mdi-open-in-new")]),_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v("Evidence triple view")])],1)])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',[_c('h5',[_vm._v("Entity harmonization data")]),_c('json-viewer',{attrs:{"theme":"json-viewer-gruvbox-dark","expand-depth":4,"value":item.mapping_data}})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }