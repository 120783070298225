












































































































































import Vue from "vue";

import LiteratureDialog from "@/components/widgets/LiteratureDialog.vue";
import TripleSummaryChart from "./TripleSummaryChart.vue";

export default Vue.extend({
  name: "TripleLiteratureEvidenceResults",
  components: {
    LiteratureDialog,
    TripleSummaryChart,
  },
  props: {
    docs: {
      type: String,
      default: "",
    },
    tripleEvidence: {
      type: Array,
      required: true,
    },
    literatureEvidence: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tripleHeaders: [
        {
          text: "#",
          value: "idx",
        },
        {
          text: "Triple",
          value: "triple_lower",
        },
        {
          text: "Subject term",
          value: "triple_subject",
        },
        {
          text: "Object term",
          value: "triple_object",
        },
        {
          text: "Direction",
          value: "direction",
        },
        {
          text: "Number of associated literature",
          value: "literature_count",
        },
        {
          text: "Mapping score",
          value: "mapping_score",
        },
        {
          text: "Triple strength score",
          value: "triple_score",
        },
        {
          text: "Evidence score",
          value: "evidence_score",
        },
        {
          text: "Links",
          value: "link",
        },
        {
          text: "Detail data",
          value: "data-table-expand",
        },
      ],
      showLiteratureDialog: false,
      tripleSelect: null,
      tripleToRender: null,
      numLiteratureItemsSelect: 10,
      numLiteratureItemsOptions: [10, 20, 50],
    };
  },
  computed: {
    stat(): Record<string, number | string> {
      const evidenceEmpty =
        this.tripleItems == null || this.tripleItems.length == 0;
      const numEvidenceItems = evidenceEmpty ? 0 : this.tripleItems.length;
      const aggEntHarmonizationScore = evidenceEmpty
        ? "NA"
        : this._.chain(this.tripleItems)
            .map((item) => item.mapping_score)
            .sum()
            .value()
            .toFixed(4);
      const aggTripleStrengthScore = evidenceEmpty
        ? "NA"
        : this._.chain(this.tripleItems)
            .map((item) => item.triple_score)
            .sum()
            .value()
            .toFixed(4);
      const avgTripleStrengthScore = evidenceEmpty
        ? "NA"
        : (aggTripleStrengthScore / numEvidenceItems).toFixed(4);
      const aggEvidenceScore = evidenceEmpty
        ? "NA"
        : this._.chain(this.tripleItems)
            .map((item) => item.evidence_score)
            .sum()
            .value()
            .toFixed(4);
      const avgEvidenceScore = evidenceEmpty
        ? "NA"
        : (aggEvidenceScore / numEvidenceItems).toFixed(4);
      const res = {
        numEvidenceItems: numEvidenceItems,
        aggEntHarmonizationScore: aggEntHarmonizationScore,
        aggTripleStrengthScore: aggTripleStrengthScore,
        avgTripleStrengthScore: avgTripleStrengthScore,
        aggEvidenceScore: aggEvidenceScore,
        avgEvidenceScore: avgEvidenceScore,
      };
      return res;
    },
    tripleItems(): Array<any> {
      const res = this._.chain(this.tripleEvidence)
        .map((item) => {
          const subj = item.triple_subject.replace(" ", "+");
          const obj = item.triple_object.replace(" ", "+");
          const pred = item.triple_predicate;
          const link = `triple?subject=${subj}&object=${obj}&predicate=${pred}`;
          const res = {
            ...item,
            link: link,
          };
          return res;
        })
        .value();
      return res;
    },
    tripleOptions(): Array<any> {
      const res = this._.chain(this.tripleEvidence)
        .map((item) => ({
          triple_id: item.triple_id,
          triple_label: item.triple_label,
          idx: item.idx,
        }))
        .value();
      return res;
    },
    histogramSeries(): Record<string, number> {
      const tripleScores = this._.chain(this.tripleItems)
        .map((e) => e.triple_score)
        .value();
      const evidenceScores = this._.chain(this.tripleItems)
        .map((e) => e.evidence_score)
        .value();
      const res = {
        tripleScores: tripleScores,
        evidenceScores: evidenceScores,
      };
      return res;
    },
    triplesForLiterature(): Array<Record<string, string>> {
      const items = this._.chain(this.tripleEvidence)
        .map((item) => ({
          triple_id: item.triple_id,
          triple_label: item.triple_lower,
          subject_term: item.triple_subject,
          object_term: item.triple_object,
        }))
        .value();
      return items;
    },
  },
  methods: {
    toggleFullscreen(elemId) {
      const elem = this.$el.querySelector(elemId);
      this.$fullscreen.toggle(elem);
    },
  },
});
