






































































































































































import Vue from "vue";

import ForestPlot from "./AssocForestPlot.vue";
import AssocSummaryChart from "./AssocSummaryChart.vue";

export default Vue.extend({
  name: "EvidenceResults",
  components: {
    ForestPlot,
    AssocSummaryChart,
  },
  props: {
    docs: {
      type: String,
      default: "",
    },
    assocEvidence: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      focus: false,
      assocHeaders: [
        {
          text: "#",
          value: "idx",
        },
        {
          text: "Subject",
          value: "subject_term",
        },
        {
          text: "Object",
          value: "object_term",
        },
        {
          text: "Direction",
          value: "direction",
        },
        {
          text: "Source",
          value: "meta_rel",
        },
        {
          text: "Effect size",
          value: "effect_size",
        },
        {
          text: "SE",
          value: "se",
        },
        {
          text: "P-Value",
          value: "pval",
        },
        {
          text: "Entity harmonization score",
          value: "mapping_score",
        },
        {
          text: "Association strength score",
          value: "assoc_score",
        },
        {
          text: "Evidence score",
          value: "evidence_score",
        },
        {
          text: "Links",
          value: "links",
        },
        {
          text: "Detail data",
          value: "data-table-expand",
        },
      ],
    };
  },
  computed: {
    stat(): Record<string, number | string> {
      const evidenceEmpty =
        this.assocItems == null || this.assocItems.length == 0;
      const numEvidenceItems = evidenceEmpty ? 0 : this.assocItems.length;
      const aggEntHarmonizationScore = evidenceEmpty
        ? "N/A"
        : this._.chain(this.assocItems)
            .map((item) => item.mapping_score)
            .sum()
            .value()
            .toFixed(4);
      const aggAssocStrengthScore = evidenceEmpty
        ? "N/A"
        : this._.chain(this.assocItems)
            .map((item) => item.assoc_score)
            .sum()
            .value()
            .toFixed(4);
      const avgAssocStrengthScore = evidenceEmpty
        ? "NA"
        : (aggAssocStrengthScore / numEvidenceItems).toFixed(4);
      const aggEvidenceScore = evidenceEmpty
        ? "N/A"
        : this._.chain(this.assocItems)
            .map((item) => item.evidence_score)
            .sum()
            .value()
            .toFixed(4);
      const avgEvidenceScore = evidenceEmpty
        ? "NA"
        : (aggEvidenceScore / numEvidenceItems).toFixed(4);
      const res = {
        numEvidenceItems: numEvidenceItems,
        aggEntHarmonizationScore: aggEntHarmonizationScore,
        aggAssocStrengthScore: aggAssocStrengthScore,
        avgAssocStrengthScore: avgAssocStrengthScore,
        aggEvidenceScore: aggEvidenceScore,
        avgEvidenceScore: avgEvidenceScore,
      };
      return res;
    },
    assocItems(): Array<any> {
      const res = this._.chain(this.assocEvidence.data)
        .map((item) => {
          var larrow = "";
          var rarrow = "";
          if (item["direction"] == "forward") {
            larrow = "";
            rarrow = ">";
          } else if (item["direction"] == "reverse") {
            larrow = "<";
            rarrow = "";
          } else if (item["direction"] == "undirectional") {
            larrow = " ";
            rarrow = "";
          }
          const idx = item.idx;
          const critVal = 1.96;
          const label = `<b>${idx}</b> ${item.subject_term} ${larrow}-${rarrow} ${item.object_term}`;
          const lbound = item["effect_size"] - critVal * item["se"];
          const ubound = item["effect_size"] + critVal * item["se"];
          const topicUrl =
            item.meta_rel !== "MR_EVE_MR"
              ? null
              : this.mrUrlFormatter(
                  item.subject_term,
                  item.object_term,
                  item.pval,
                );
          const res = {
            ...item,
            lbound: lbound,
            ubound: ubound,
            label: label,
            topicUrl: topicUrl,
            links: null,
            idx: idx,
          };
          return res;
        })
        .value();
      return res;
    },
    histogramSeries(): Record<string, number> {
      const assocScores = this._.chain(this.assocItems)
        .map((e) => e.assoc_score)
        .value();
      const evidenceScores = this._.chain(this.assocItems)
        .map((e) => e.evidence_score)
        .value();
      const res = {
        assocScores: assocScores,
        evidenceScores: evidenceScores,
      };
      return res;
    },
  },
  methods: {
    mrUrlFormatter(
      subjectTerm: string,
      objectTerm: string,
      pval: number,
    ): string | null {
      if (pval > 0.1) return null;
      const subj = subjectTerm.replace(" ", "+");
      const obj = objectTerm.replace(" ", "+");
      const pvalStr = pval <= 0.01 ? "1e-2" : "1e-1";
      const url = "https://epigraphdb.org/mr/";
      const res = `${url}?exposure-query=${subj}&outcome-query=${obj}&pval=${pvalStr}`;
      return res;
    },
    toggleFullscreen(elemId) {
      const elem = this.$el.querySelector(elemId);
      this.$fullscreen.toggle(elem);
    },
  },
});
