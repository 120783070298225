











































import Vue from "vue";

type Outline = Record<
  string,
  {
    ref: string;
    label: string;
    shortLabel?: string;
    focus: boolean;
    lv?: number;
  }
>;

export default Vue.extend({
  name: "Toc",
  props: {
    outline: {
      type: Object as () => Outline,
      required: true,
    },
  },
});
