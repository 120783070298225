






















import Vue from "vue";
import { PropType } from "vue";

import LiteratureDetail from "@/components/widgets/LiteratureDetail.vue";
export default Vue.extend({
  name: "LiteratureDialog",
  components: {
    LiteratureDetail,
  },
  props: {
    item: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    triple: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
  },
  data() {
    return {
      showLiteratureDialog: false,
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
});
