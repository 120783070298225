var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"header.ic_score",fn:function(ref){
var header = ref.header;
return [_c('tooltip',{attrs:{"docs":_vm.$store.state.docs.params.paramIcScore}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.identity_score",fn:function(ref){
var header = ref.header;
return [_c('tooltip',{attrs:{"docs":_vm.$store.state.docs.params.paramIdentityScore}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.similarity_score",fn:function(ref){
var header = ref.header;
return [_c('tooltip',{attrs:{"docs":_vm.$store.state.docs.params.paramSimilarityScore}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"item.ent_term",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"font-weight-thin"},[_vm._v("Efo")]),_vm._v("   "),_c('span',[_c('code',[_vm._v(_vm._s(item.ent_id))])]),_c('br'),_c('a',{attrs:{"href":item.ent_url,"target":"_blank"}},[_c('span',[_vm._v(_vm._s(item.ent_term))])])])]}},{key:"item.ref_ent_term",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"font-weight-thin"},[_vm._v("QueryUmls")]),(_vm.refEntValid(item.ref_ent_id))?_c('span',[_vm._v("   "),_c('code',[_vm._v(_vm._s(item.ref_ent_id))])]):_vm._e(),_c('br'),_c('span',[_vm._v(_vm._s(item.ref_ent_term))])])]}},{key:"item.similarity_score",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.similarity_score.toFixed(2))+" ")])]}},{key:"item.ic_score",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.ic_score.toFixed(2))+" ")])]}},{key:"item.identity_score",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.identity_score.toFixed(2))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }