




























































import Vue from "vue";

import Tooltip from "@/components/widgets/Tooltip.vue";

import {
  paramSimilarityScore,
  paramIcScore,
  paramIdentityScore,
} from "@/resources/docs/params";

export default Vue.extend({
  name: "EntityItem",
  components: {
    Tooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    entType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      paramIcScore: paramIcScore,
      paramSimilarityScore: paramSimilarityScore,
      paramIdentityScore: paramIdentityScore,
    };
  },
});
