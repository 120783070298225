




























import Vue from "vue";

import * as processing from "@/funcs/processing";

import NetworkPlot from "./NetworkPlot.vue";
import SummaryChart from "./SummaryChart.vue";
import SummaryStats from "./SummaryStats.vue";
import * as types from "@/types/types";

export default Vue.extend({
  name: "EvidenceSummary",
  components: {
    NetworkPlot,
    SummaryChart,
    SummaryStats,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      summaryTab: null,
    };
  },
  computed: {
    summaryData(): any {
      return this.data;
    },
  },
  async mounted() {
    //
  },
  methods: {
    toggleFullscreen(elemId) {
      const elem = this.$el.querySelector(elemId);
      this.$fullscreen.toggle(elem);
    },
  },
});
