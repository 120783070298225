








































import Vue from "vue";
import { PropType } from "vue";

import EfoMappingTable from "@/components/widgets/EfoMappingTable.vue";
import OntologyDiagramMappingTable from "@/components/widgets/OntologyDiagramMappingTable.vue";
import OntologyPlot from "@/components/widgets/OntologyPlot.vue";
import * as types from "@/types/types";
import * as processing from "@/funcs/processing";

export default Vue.extend({
  name: "OntologySummary",
  components: {
    EfoMappingTable,
    OntologyDiagramMappingTable,
    OntologyPlot,
  },
  props: {
    ontologyMappingData: {
      type: Object as PropType<Record<string, Array<any>>>,
      required: true,
    },
  },
  data() {
    return {
      ontologyDiagramSelect: null,
      ontologyPlotData: null,
      tabs: null,
    };
  },
  computed: {
    ontologyEnts(): Array<types.BaseEnt> {
      const res = this._.chain(this.$store.getters["ents/ontologyData"])
        .mapValues((item) => item.ents)
        .values()
        .flatten()
        .uniqWith(this._.isEqual)
        .map((item) => ({
          ent_id: item.ent_id,
          ent_term: item.ent_term,
        }))
        .value();
      console.log(res);
      return res;
    },
  },
  mounted: async function (): Promise<void> {
    const queryTerms = [
      this.$store.state.ents.claimTriple.sub_term,
      this.$store.state.ents.claimTriple.obj_term,
    ];
    this.ontologyPlotData = await processing.makeOntologyPlotData(
      this.ontologyEnts,
      queryTerms,
    );
  },
  methods: {
    //
  },
});
