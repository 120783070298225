export const theme = {
  colors: [
    "#337ab7",
    "#7cb342",
    "#ED561B",
    "#DDDF00",
    "#24CBE5",
    "#64E572",
    "#FF9655",
    "#FFF263",
    "#6AF9C4",
  ],
};
