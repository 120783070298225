




































































































import Vue from "vue";
import * as paramDocs from "@/resources/docs/params";
import ParamSubheader from "@/components/widgets/ParamSubheader.vue";

export default Vue.extend({
  name: "AdjustResultsParams",
  components: {
    ParamSubheader,
  },
  data() {
    return {
      labels: {
        // ent harmonization
        ontologyNumCandidates: {
          label: "Number of entity candidates to retrieve",
          docs: paramDocs.paramNumOntologyEntCandidates,
        },
        ontologySimilarityScoreThreshold: {
          label: "Semantic similarity threshold (query vs. Ontology)",
          docs: paramDocs.paramSimilarityScore,
        },
        ontologyIcScoreThreshold: {
          label: "Ontology information score threshold",
          docs: paramDocs.paramIcScore,
        },
        ontologyIdentityScoreThreshold: {
          label: "Ontology identity score",
          docs: paramDocs.paramIdentityScore,
        },
        // rest
        postOntologyNumCandidates: {
          label: "Number of UMLS entities / GWAS traits to retrieve",
          docs: paramDocs.postOntologyNumCandidates,
        },
        postOntologySimilarityScoreThreshold: {
          label: "Semantic similarity threshold (Ontology vs. UMLS/GWAS)",
          docs: paramDocs.paramSimilarityScore,
        },
        assocPvalThreshold: {
          label: "Association evidence P-Value threshold",
          docs: paramDocs.assocPvalThreshold,
        },
      },
      storeParams: [
        "ontologyNumCandidates",
        "ontologySimilarityScoreThreshold",
        // "ontologyIdentityScoreThreshold",
        // "ontologyIcScoreThreshold",
        "postOntologyNumCandidates",
        "postOntologySimilarityScoreThreshold",
        "assocPvalThreshold",
      ],
      // builtin options
      pvalOptions: ["1e-1", "5e-2", "1e-2", "1e-3", "1e-5"],
      similarityScoreOptions: [0.5, 0.6, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 0.99],
      numEntsOptions: [10, 20, 30, 50],
      icScoreOptions: [0.3, 0.4, 0.5, 0.6, 0.7, 0.8],
      identityScoreOptions: [1.0, 1.2, 1.5, 2.0, 3.0],
      // store ents
      ontologyNumCandidates: this.$store.state.params.postOntologyNumCandidates,
      ontologySimilarityScoreThreshold:
        this.$store.state.params.ontologySimilarityScoreThreshold,
      ontologyIdentityScoreThreshold:
        this.$store.state.params.ontologyIdentityScoreThreshold,
      ontologyIcScoreThreshold:
        this.$store.state.params.ontologyIcScoreThreshold,
      postOntologyNumCandidates:
        this.$store.state.params.postOntologyNumCandidates,
      postOntologySimilarityScoreThreshold:
        this.$store.state.params.postOntologySimilarityScoreThreshold,
      assocPvalThreshold: this.$store.state.params.assocPvalThreshold,
    };
  },
  computed: {
    //
  },
  async mounted(): Promise<void> {
    //
  },
  methods: {
    async update(): Promise<void> {
      await this._.chain(this.storeParams)
        .map((param) => {
          this.$store.dispatch("params/updateParam", {
            key: param,
            value: this[param],
          });
        })
        .value();
      await this.$emit("regen");
    },
  },
});
