


















































import Vue from "vue";

import AdjustResultsParams from "./AdjustResultsParams.vue";
import AdjustOntologyEnts from "./AdjustOntologyEnts.vue";
import Loading from "@/components/widgets/Loading.vue";

import * as processing from "@/funcs/processing";

export default Vue.extend({
  name: "AdjustResults",
  components: {
    AdjustResultsParams,
    AdjustOntologyEnts,
    Loading,
  },
  data() {
    return {
      showDialog: false,
      stage: 1,
      refresh: 0,
      loading: false,
      loadingMessage: "",
    };
  },
  computed: {
    //
  },
  methods: {
    async updateEnts(): Promise<void> {
      this.stage = 2;
      this.loading = true;
      this.loadingMessage = "Retrieving ontology entities";
      await this.refreshOntologyEnts();
      this.refresh = this.refresh + 1;
      this.loading = false;
    },
    async refreshOntologyEnts(): Promise<void> {
      const triple = this.$store.state.ents.claimTriple;
      await processing.getOntologyEnts(triple);
    },
    async updateEvidence(): Promise<void> {
      this.showDialog = false;
      await this.$emit("regen");
    },
  },
});
