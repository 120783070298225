


























































import Vue from "vue";

import { semanticTypeUrl, umlsPredicateUrl } from "@/resources/resources";

export default Vue.extend({
  name: "ClaimTriple",
  props: {
    item: {
      type: Object,
      required: true,
    },
    htmlText: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    semanticTypeUrl: semanticTypeUrl,
    umlsPredicateUrl: umlsPredicateUrl,
  }),
});
