

















import Vue from "vue";

export default Vue.extend({
  name: "ParamSubheader",
  components: {
    //
  },
  props: {
    docs: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      //
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
});
