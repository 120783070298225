

























































































import Vue from "vue";
export default Vue.extend({
  name: "AppFooter",
  data: () => ({
    hide: false,
  }),
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    footerId() {
      if (this.currentRouteName == "TripleView") {
        return "footer-triple";
      } else {
        return "footer";
      }
    },
    queryFinished(): boolean {
      return this.$store.getters["queryStage/queryAllDone"];
    },
    queryTriple(): string | null {
      const latestStage = this.$store.state.queryStage.latestStage;
      const nonStandard = !this.$store.state.queryStage.standardQueryMode;
      const stageReached = !nonStandard ? latestStage > 2 : latestStage > 1;
      if (!stageReached) {
        return null;
      } else {
        const queryTriple = this.$store.state.ents.claimTriple;
        const queryTripleLabel = `<code style="color:#ffd740">${queryTriple.sub_term}-${queryTriple.pred}->${queryTriple.obj_term}</code>`;
        const res = ` &nbsp;  &nbsp; query triple: ${queryTripleLabel}`;
        return res;
      }
    },
    action(): string {
      const res = this.$store.getters["queryStage/stageAction"];
      return res;
    },
  },
  mounted: function () {
    //
  },
  methods: {
    //
  },
});
