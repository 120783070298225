





















import Vue from "vue";
import { PropType } from "vue";
import { Triple } from "@/types/types.ts";

export default Vue.extend({
  name: "InvalidTripleDialog",
  components: {
    //
  },
  props: {
    triples: {
      type: Array as PropType<Array<Triple>>,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
});
