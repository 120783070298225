var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.loading)?_c('loading-screen',{attrs:{"message":_vm.message,"stage":_vm.loadingStage}}):_vm._e(),(!_vm.loading)?_c('v-row',[_c('v-col',[_c('h2',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],ref:"evidence-summary",attrs:{"id":"evidence-summary"}},[_vm._v(" Evidence summary ")]),_c('p',{staticClass:"blockquote"},[_c('vue-markdown',{attrs:{"source":_vm.$store.state.docs.general.evidenceSummary
              .split('\n')
              .splice(1)
              .join('\n'),"breaks":false}})],1),_c('v-card',[_c('v-card-title',[_c('h3',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],ref:"summary-data",attrs:{"id":"summary-data"}},[_vm._v(" Summary data ")])]),_c('v-card-text',[(_vm.queryAllDone && _vm.summaryData)?_c('evidence-summary',{attrs:{"data":_vm.summaryData},on:{"regen":_vm.getData}}):_vm._e()],1)],1),_c('v-divider',{staticClass:"py-3"}),_c('v-card',[_c('v-card-title',[_c('h3',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],ref:"ontology-mapping",attrs:{"id":"ontology-mapping"}},[_vm._v(" Ontology entity mapping ")])]),_c('v-card-text',[(_vm.ontologyMappingData)?_c('div',[_c('ontology-summary',{attrs:{"ontology-mapping-data":_vm.ontologyMappingData}})],1):_vm._e()])],1),_c('v-divider',{staticClass:"py-3"}),_c('div',{staticClass:"py-5"}),_c('h2',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],ref:"triple-literature-evidence",attrs:{"id":"triple-literature-evidence"}},[_vm._v(" Knowledge triple and literature evidence ")]),_c('p',{staticClass:"blockquote"},[_c('vue-markdown',{attrs:{"source":_vm.$store.state.docs.general.tripleLiteratureEvidence
              .split('\n')
              .splice(1)
              .join('\n'),"breaks":false}})],1),_c('v-card',[_c('v-card-title',[_c('h3',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],ref:"umls-mapping",attrs:{"id":"umls-mapping"}},[_vm._v(" UMLS entity mapping ")])]),_c('v-card-text',[(_vm.umlsData)?_c('div',[_c('v-row',[_c('v-col',[_c('h4',[_vm._v("Subject mapping")]),_c('mapping-table',{attrs:{"data":_vm.umlsData.subjects.detailData}})],1),_c('v-col',[_c('h4',[_vm._v("Object mapping")]),_c('mapping-table',{attrs:{"data":_vm.umlsData.objects.detailData}})],1)],1)],1):_vm._e()])],1),_c('v-divider',{staticClass:"py-3"}),(_vm.queryAllDone)?_c('div',_vm._l((_vm.tripleEvidenceTypes.map(function (key) { return ({ key: key }); })),function(item){return _c('div',{key:item.key},[_c('v-card',[_c('v-card-title',[_c('h3',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],ref:("triple-" + (item.key)),refInFor:true,attrs:{"id":("triple-" + (item.key))}},[_vm._v(" "+_vm._s(_vm.outlineTripleItems[("triple-" + (item.key))].label)+" ")])]),_c('v-card-text',[(_vm.tripleEvidence[item.key])?_c('triple-evidence-results',{attrs:{"docs":_vm.tripleEvidenceDocs[item.key],"triple-evidence":_vm.tripleEvidence[item.key],"literature-evidence":_vm.literatureEvidence[item.key]}}):_vm._e()],1)],1)],1)}),0):_vm._e(),_c('div',{staticClass:"py-5"}),_c('h2',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],ref:"assoc-evidence",attrs:{"id":"assoc-evidence"}},[_vm._v(" Association evidence ")]),_c('p',{staticClass:"blockquote"},[_c('vue-markdown',{attrs:{"source":_vm.$store.state.docs.general.assocEvidence
              .split('\n')
              .splice(1)
              .join('\n'),"breaks":false}})],1),_c('v-card',[_c('v-card-title',[_c('h3',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],ref:"trait-mapping",attrs:{"id":"trait-mapping"}},[_vm._v(" GWAS trait entity mapping ")])]),(_vm.traitData)?_c('div',[_c('v-row',[_c('v-col',[_c('h4',[_vm._v("Subject mapping")]),_c('mapping-table',{attrs:{"data":_vm.traitData.subjects.detailData}})],1),_c('v-col',[_c('h4',[_vm._v("Object mapping")]),_c('mapping-table',{attrs:{"data":_vm.traitData.objects.detailData}})],1)],1)],1):_vm._e(),_c('v-card-text')],1),_c('v-divider',{staticClass:"py-3"}),(_vm.queryAllDone)?_c('div',_vm._l((_vm.assocEvidenceTypes.map(function (key) { return ({ key: key }); })),function(item){return _c('div',{key:item.key},[_c('v-card',[_c('v-card-title',[_c('h3',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],ref:("assoc-" + (item.key)),refInFor:true,attrs:{"id":("assoc-" + (item.key))}},[_vm._v(" "+_vm._s(_vm.outlineAssocItems[("assoc-" + (item.key))].label)+" ")])]),_c('v-card-text',[(_vm.assocEvidence[item.key])?_c('assoc-evidence-results',{attrs:{"docs":_vm.assocEvidenceDocs[item.key],"assoc-evidence":_vm.assocEvidence[item.key]}}):_vm._e()],1)],1),_c('v-divider',{staticClass:"py-3"})],1)}),0):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[(_vm.outline)?_c('toc',{attrs:{"outline":_vm.outline},on:{"goto":_vm.jump}}):_vm._e()],1)],1):_vm._e(),(!_vm.loading)?_c('adjust-results',{on:{"regen":_vm.updateData}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }