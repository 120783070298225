


















import Vue from "vue";

import AdjustResultsParams from "./AdjustResultsParams.vue";

export default Vue.extend({
  name: "AdjustResults",
  components: {
    AdjustResultsParams,
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    //
  },
  methods: {
    async update(): Promise<void> {
      this.showDialog = false;
      await this.$emit("update");
    },
  },
});
