















import Vue from "vue";

export default Vue.extend({
  name: "SnackbarWidget",
  computed: {
    snackbar() {
      return this.$store.state.snackbar;
    },
  },
  methods: {
    closeSnackbar() {
      this.$store.commit("snackbar/closeSnackbar");
    },
  },
});
