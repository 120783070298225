
































































































import Vue from "vue";

// @ts-ignore
import defaultText from "@/resources/query-claim-default.md";
import { topics } from "@/resources/query-candidates";
import { checkStageComplete } from "@/funcs/utils";
import { parseClaim } from "@/funcs/backend_requests";

import AdjustResults from "@/components/results/AdjustResultsPreQuery.vue";

export default Vue.extend({
  name: "QueryClaim",
  components: {
    AdjustResults,
  },
  props: {
    stage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      title: "Insert query text",
      customClaimTextInput: "",
      usingCustomText: false,
      loading: false,
      charMaxLen: null,
      topicSelect: "obesity",
      topics: topics,
      literatureSelect: "dixon-peters-2018",
    };
  },
  computed: {
    stageCompleted(): boolean {
      const completed = checkStageComplete(this.stage);
      return completed;
    },
    progButtonDisabled(): boolean {
      const res = [
        this.textEmpty,
        this.stageCompleted,
        this.textSizeReached,
      ].reduce((a, b) => a || b);
      return res;
    },
    textEmpty(): boolean {
      const str = this.inputText;
      return str == null || /^\s*$/.test(str);
    },
    textSizeReached(): boolean {
      const res =
        this.inputText !== null && this.inputText.length > this.charMaxLen;
      return res;
    },
    topicOptions(): Array<Record<string, string>> {
      const res = this._.chain(topics)
        .mapValues((item) => ({
          value: item.key,
          text: item.label,
        }))
        .values()
        .value();
      return res;
    },
    queryOptions(): Array<Record<string, string>> {
      const res = this._.chain(this.literatureCandidates)
        .mapValues((item) => ({
          value: item.key,
          text: item.title,
        }))
        .values()
        .value();
      return res;
    },
    literatureCandidates(): Record<string, Record<string, string>> {
      const candidates = this.topics[this.topicSelect].candidates;
      return candidates;
    },
    builtinText(): string {
      if (this.literatureSelect) {
        return this.literatureCandidates[this.literatureSelect].text;
      } else {
        return "";
      }
    },
    inputText(): string {
      return this.usingCustomText
        ? this.customClaimTextInput
        : this.builtinText;
    },
    btnLabel(): string {
      const good = "Confirm and proceed";
      const empty = "Awaiting text input";
      const sizeReached = "Reduce size of the input text";
      const finished = "Query completed (refresh session for a new query)";
      if (this.textEmpty) {
        return empty;
      } else if (this.stageCompleted) {
        return finished;
      } else if (this.textSizeReached) {
        return sizeReached;
      } else {
        return good;
      }
    },
  },
  watch: {
    topicSelect(newVal) {
      if (newVal) {
        this.literatureSelect = this.queryOptions[0].value;
      }
    },
  },
  mounted: function () {
    this.charMaxLen = this.$store.state.params.claimTextMaxCharLen;
  },
  methods: {
    reload() {
      window.location.reload();
    },
    async submit(): Promise<void> {
      await this.update();
    },
    async update(): Promise<void> {
      this.loading = true;
      await this.$store.dispatch("claimData/updateClaimText", this.inputText);
      const parseResults = await parseClaim(this.inputText);
      await this.$store.dispatch("claimData/updateParseResults", parseResults);
      this.loading = false;
      await this.$store.dispatch("queryStage/completeStage", this.stage);
    },
    showBuiltinText(key: string): string {
      const text = this.literatureCandidates[key].text;
      const cutoff = 500;
      const subtext =
        text.length > cutoff ? text.substring(0, cutoff) + "..." : text;
      const res = subtext;
      return res;
    },
  },
});
