














































import Vue from "vue";

export default Vue.extend({
  name: "LiteratureContext",
  props: {
    data: {
      type: Object,
      required: true,
    },
    htmlText: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatUrl(doi): string {
      return `https://doi.org/${doi}`;
    },
  },
});
