































import Vue from "vue";

import QueryClaim from "@/components/stages/QueryClaim.vue";
import TripleSelect from "@/components/stages/TripleSelect.vue";
import EntHarmonizationOntology from "@/components/stages/EntHarmonizationOntology.vue";

export default Vue.extend({
  name: "StageStepper",
  components: {
    QueryClaim,
    TripleSelect,
    EntHarmonizationOntology,
  },
  data: () => ({
    stepTitles: {
      stage1: "Insert query text",
      stage2: "Select a claim triple",
      stage3: "Entity harmonization in ontology",
    },
  }),
  computed: {
    stage: {
      get() {
        return this.$store.state.queryStage.currentStage;
      },
      set(value: number) {
        this.$store.dispatch("queryStage/setCurrentStage", value);
      },
    },
  },
  methods: {},
});
