





























































import Vue from "vue";
import StageStepper from "@/components/StageStepper.vue";
import Results from "@/components/Results.vue";

export default Vue.extend({
  name: "Home",
  components: {
    StageStepper,
    Results,
  },
  data() {
    return {
      panels: [0],
      resultPanelDisabled: true,
      showDocsTooltip: true,
    };
  },
  computed: {
    queryAllDone(): boolean {
      const res = this.$store.getters["queryStage/queryAllDone"];
      return res;
    },
    showLogo(): boolean {
      const res = this.$store.state.queryStage.latestStage == 1;
      return res;
    },
    queryHeader(): string {
      if (this.queryAllDone) {
        return "Claim query (complete)";
      } else {
        return "Claim query";
      }
    },
    resultsHeader(): string {
      if (this.queryAllDone) {
        return "Evidence results";
      } else {
        // return "Evidence results (inactive)";
        return "";
      }
    },
  },
  watch: {
    queryAllDone(newVal) {
      if (newVal) {
        this.panels = [1];
        this.resultPanelDisabled = false;
      }
    },
  },
  mounted: async function (): Promise<void> {
    this.timeoutTooltip();
    await this.$store.dispatch("queryStage/setQueryMode", "standard");
  },
  methods: {
    timeoutTooltip(): void {
      setTimeout(
        function () {
          if (this.showDocsTooltip) {
            this.showDocsTooltip = false;
          }
        }.bind(this),
        3000,
      );
    },
  },
});
