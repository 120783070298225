










































































import Vue from "vue";

import ClaimTriple from "@/components/widgets/ClaimTriple.vue";
import { checkStageComplete } from "@/funcs/utils.ts";
import { Triple } from "@/types/types.ts";
import ClaimTextDisplay from "@/components/widgets/ClaimTextDisplay.vue";
import InvalidTripleDialog from "@/components/widgets/InvalidTripleDialog.vue";
import * as backendRequests from "@/funcs/backend_requests";
import * as processing from "@/funcs/processing";

export default Vue.extend({
  name: "TripleSelect",
  components: {
    ClaimTriple,
    ClaimTextDisplay,
    InvalidTripleDialog,
  },
  props: {
    stage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      title: "Select a claim triple",
      selectedTriple: null,
      loading: false,
    };
  },
  computed: {
    pageDocs(): string {
      return this.$store.state.docs.general.tripleSelect
        .split("\n")
        .splice(1)
        .join("\n");
    },
    inactiveMessage(): string {
      const stageNotReachedMessage =
        "Claim text has not been processed. You should revert to last stage.";
      const emptyResultsMessage =
        "No results have been parsed successfully. You should revert to last stage and adjust your query.";
      if (this.tripleResultsEmpty) {
        return emptyResultsMessage;
      } else {
        return stageNotReachedMessage;
      }
    },
    active(): boolean {
      return this.$store.getters["claimData/claimParsed"];
    },
    tripleResultsEmpty(): boolean {
      const stageReached =
        this.$store.state.queryStage.latestStage == this.stage;
      const tripleEmpty = !this.$store.getters["claimData/claimParsed"];
      const res = tripleEmpty && stageReached;
      return res;
    },
    claimData(): null | Record<string, any> {
      return this.$store.state.claimData.claimTriples.length > 0
        ? this.$store.state.claimData
        : null;
    },
    claimText(): null | Array<string> {
      return this.claimData.sents.length > 0 ? this.claimData.sents : null;
    },
    claimTriples(): Array<Triple> {
      return this.claimData.claimTriples;
    },
    invalidTriples(): Array<Record<string, any>> {
      return this.$store.state.claimData.invalidTriples;
    },
    htmlDisplay(): Array<string> {
      return this.claimData.htmlDisplay;
    },
    buttonDisabled(): boolean {
      const locked = checkStageComplete(this.stage);
      const inputEmpty = !this.selectedTriple;
      const res = [locked, inputEmpty].reduce((a, b) => a || b);
      return res;
    },
    btnLabel(): string {
      const good = "Confirm and proceed";
      const inactive = "Awaiting completion of previous stage";
      const empty = "Awaiting triple selection";
      const finished = "Query finished";
      if (!this.active) {
        return inactive;
      } else if (checkStageComplete(this.stage)) {
        return finished;
      } else if (!this.selectedTriple) {
        return empty;
      } else {
        return good;
      }
    },
  },
  mounted: function () {
    const claimTriple = this.$store.state.ents.claimTriple;
    const completed = checkStageComplete(this.stage);
    if (claimTriple && completed) {
      this.selectedTriple = claimTriple;
    }
  },
  methods: {
    async update(): Promise<void> {
      this.loading = true;
      await this.$store.dispatch("ents/submitClaimTriple", this.selectedTriple);
      await processing.getOntologyEnts(this.selectedTriple);
      this.loading = false;
      await this.$store.dispatch("queryStage/completeStage", this.stage);
    },
  },
});
