

























































import Vue from "vue";
import { refEntValid } from "@/funcs/utils";

export default Vue.extend({
  name: "MappingTable",
  components: {
    //
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Entity",
          value: "ent_term",
        },
        {
          text: "Reference entity",
          value: "ref_ent_term",
        },
        {
          text: "Semantic similarity",
          value: "similarity_score",
        },
      ],
    };
  },
  computed: {
    items(): Array<any> {
      return this.data;
    },
  },
  methods: {
    refEntValid(item: string | null): boolean {
      return refEntValid(item);
    },
  },
});
