














import Vue from "vue";
import { PropType } from "vue";

export default Vue.extend({
  name: "ClaimTextDisplay",
  props: {
    sents: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  computed: {
    sentItems() {
      const items = this._.chain(this.sents)
        .map((item, idx) => {
          return {
            idx: idx,
            sent: item,
          };
        })
        .value();
      return items;
    },
  },
});
