







import Vue from "vue";

export default Vue.extend({
  name: "Loading",
  props: {
    message: {
      type: String,
      default: null,
    },
  },
});
